/* You can add global styles to this file, and also import other style files */

/*================================================
Default CSS
=================================================*/
$main-font-family: "Open Sans", sans-serif;
$optional-font-family: "Dosis", sans-serif;
$main-color: #07737f;
$optional-color: #303e66;
// $optional-color: #4ac728;
$white-color: #fff;
$black-color: #000c;
$paragraph-color: #57647c;
$font-size: 14px;
$transition: 0.5s;
* {
  scroll-behavior: smooth;
}
body {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  font: {
    family: $main-font-family;
    size: $font-size;
  }
}
.tag {
  color: #fff;
  padding: 5px 8px !important;
  font-size: 12px !important;
  margin: 0.3rem;
}
.voir-plus {
  text-align: center;
  padding-top: 30px;
}
a {
  display: block;
  transition: $transition;
  text-decoration: none;
  color: $black-color;

  &:hover,
  &:focus {
    text-decoration: none;
    color: $main-color;
  }
}
button {
  outline: 0 !important;
}
.light-border-bottom {
  border-bottom: 1px solid #dadada;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $black-color;
  font: {
    family: $optional-font-family;
  }
}
.d-table {
  width: 100%;
  height: 100%;

  &-cell {
    vertical-align: middle;
  }
}
p {
  font-size: $font-size;
  color: $paragraph-color;
  margin-bottom: 15px;
  line-height: 1.8;

  &:last-child {
    margin-bottom: 0;
  }
}
.owl-carousel .owl-item img {
  display: block;
  width: auto;
}
.ptb-110 {
  padding: {
    top: 60px;
    bottom: 60px;
  }
}
.ptb-50 {
  padding: {
    top: 50px;
    bottom: 50px;
  }
}
.pt-12 {
  padding-top: 12px;
}
.mt-40 {
  margin-top: 40px;
}
img {
  max-width: 100%;
}
.bg-f2f6f9 {
  background-color: #f2f6f9;
}
.bg-fafafa {
  background-color: #fafafa;
}
/*section-title*/
.section-title {
  text-align: center;
  max-width: 670px;
  margin: {
    left: auto;
    right: auto;
    bottom: 60px;
    top: -6px;
  }
  span {
    display: block;
    color: $main-color;
    text-transform: uppercase;
    margin-bottom: 5px;
    font: {
      weight: 600;
      size: 15px;
      family: $optional-font-family;
    }
  }
  h2 {
    margin-bottom: 0;
    font: {
      size: 40px;
      weight: 700;
    }
  }
  p {
    max-width: 520px;
    margin: {
      bottom: 0;
      top: 10px;
      left: auto;
      right: auto;
    }
  }
}
/*btn-primary*/
.btn {
  border: none;
  line-height: initial;
  border-radius: 30px;
  transition: $transition;
  padding: 10px 32px 12px;
  position: relative;
  z-index: 1;
  opacity: 1 !important;
  font: {
    size: 16px;
    family: $optional-font-family;
    weight: 600;
  }
}
.btn-primary {
  color: $white-color;
  background-color: $main-color;

  &.disabled,
  &:disabled {
    color: $white-color;
    background-color: $main-color;
  }
  &:hover,
  &:focus {
    background-color: $optional-color !important;
    color: $white-color !important;
    box-shadow: unset !important;
  }
}
/*learn-more-btn*/
.learn-more-btn {
  display: inline-block;
  position: relative;
  color: $main-color;
  letter-spacing: 0.5px;
  font: {
    size: 15px;
    weight: 600;
    family: $optional-font-family;
  }
  i {
    margin-left: 2px;
    position: relative;
    top: -0.6px;

    &::before {
      font-size: 11px;
    }
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    background: $main-color;
    height: 1px;
    opacity: 0;
    visibility: hidden;
    transition: $transition;
  }
  &:hover {
    color: $main-color;

    &::before {
      width: 100%;
      opacity: 1;
      visibility: visible;
    }
  }
}
.m-y-2 {
  margin: 2rem 0;
}
/*form-control*/
.form-control {
  height: 45px;
  border: 1px solid #eeeeee;
  background-color: transparent;
  border-radius: 0;
  transition: $transition;
  color: $black-color;
  line-height: initial;
  padding-left: 13px;
  font: {
    size: 14px;
  }
  &:hover,
  &:focus {
    box-shadow: unset;
  }
  &::placeholder {
    color: #999999;
  }
  &:focus {
    box-shadow: unset;
    border-color: $main-color;
  }
}

/*================================================
Preloader Area CSS
=================================================*/
.preloader {
  position: fixed;
  left: 0;
  width: 0;
  height: 100%;
  width: 100%;
  text-align: center;
  z-index: 9999999;
  transition: 0.9s;

  .loader {
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: inline-block;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 45%;
    transform: translateY(-45%);
    transition: $transition;

    .loader-outter {
      position: absolute;
      border: 4px solid $white-color;
      border-left-color: transparent;
      border-bottom: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      animation: loader-outter 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
    }
    .loader-inner {
      position: absolute;
      border: 4px solid $white-color;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      left: calc(50% - 20px);
      top: calc(50% - 20px);
      border-right: 0;
      border-top-color: transparent;
      animation: loader-inner 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
    }
  }
  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 60%;
    z-index: -1;
    background: $main-color;
    transition: 0.9s;
  }
  &::after {
    top: auto;
    bottom: 0;
  }
  &.preloader-deactivate {
    visibility: hidden;

    &::after,
    &::before {
      height: 0;
    }
    .loader {
      opacity: 0;
      visibility: hidden;
    }
  }
}
@keyframes loader-outter {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes loader-inner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

/*================================================
Navbar Area CSS
=================================================*/
.navbar-brand {
  padding: {
    top: 0;
    bottom: 0;
  }
  img {
    &.optional-logo {
      display: none;
    }
  }
}
.kirin-nav {
  background-color: #ffffffa8;
  padding: {
    top: 5px;
    bottom: 5px;
    right: 7px;
    left: 7px;
  }
  .navbar {
    padding: {
      right: 0;
      top: 0;
      left: 0;
      bottom: 0;
    }
    ul {
      padding-left: 0;
      list-style-type: none;
      margin-bottom: 0;
    }
    .navbar-nav {
      font-family: $optional-font-family;
      margin-left: auto;

      .nav-item {
        position: relative;
        padding: {
          top: 15px;
          bottom: 15px;
          left: 0;
          right: 0;
        }
        .dropdown-toggle {
          &::after {
            display: none !important;
          }
        }
        a {
          font: {
            size: 15px;
            weight: 600;
          }
          font-size: 15px;
          color: rgba(0, 0, 0, 0.5);

          text-transform: uppercase;
          padding: {
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
          }
          margin: {
            left: 25px;
            right: 25px;
          }
          &:hover,
          &:focus,
          &.active {
            color: rgba(0, 0, 0, 0.5);
          }

          &.active {
            border-bottom: 5px solid $main-color;
          }
          i {
            font-size: 10px;
          }
        }
        &:last-child {
          a {
            margin-right: 0;
          }
        }
        &:first-child {
          a {
            margin-left: 0;
          }
        }
        &:hover,
        &:focus,
        &.active {
          a {
            color: $main-color;
          }
        }
        .dropdown-menu {
          box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
          background: $white-color;
          position: absolute;
          border: none;
          top: 55px;
          left: 0;
          width: 250px;
          z-index: 99;
          border-radius: 0;
          transition: all 0.3s ease-in-out;
          padding: {
            top: 20px;
            left: 5px;
            right: 5px;
            bottom: 20px;
          }
          li {
            padding: 0;

            a {
              text-transform: capitalize;
              padding: 8px 15px;
              margin: 0;
              color: $black-color;
              font: {
                size: 16px;
              }
              &:hover,
              &:focus,
              &.active {
                color: $white-color;
              }
            }
            .dropdown-menu {
              left: 220px;
              top: 0;

              li {
                a {
                  color: $black-color;

                  &:hover,
                  &:focus,
                  &.active {
                    color: $main-color;
                  }
                }
                .dropdown-menu {
                  left: 220px;
                  top: 0;

                  li {
                    a {
                      color: $black-color;
                      text-transform: capitalize;

                      &:hover,
                      &:focus,
                      &.active {
                        color: $main-color;
                      }
                    }
                    .dropdown-menu {
                      left: -250px;
                      top: 0;

                      li {
                        a {
                          color: $black-color;
                          text-transform: capitalize;

                          &:hover,
                          &:focus,
                          &.active {
                            color: $main-color;
                          }
                        }
                        .dropdown-menu {
                          left: -250px;
                          top: 0;

                          li {
                            a {
                              color: $black-color;
                              text-transform: capitalize;

                              &:hover,
                              &:focus,
                              &.active {
                                color: $main-color;
                              }
                            }
                            .dropdown-menu {
                              left: -250px;
                              top: 0;

                              li {
                                a {
                                  color: $black-color;
                                  text-transform: capitalize;

                                  &:hover,
                                  &:focus,
                                  &.active {
                                    color: $main-color;
                                  }
                                }
                                .dropdown-menu {
                                  left: -250px;
                                  top: 0;

                                  li {
                                    a {
                                      color: $black-color;
                                      text-transform: capitalize;

                                      &:hover,
                                      &:focus,
                                      &.active {
                                        color: $main-color;
                                      }
                                    }
                                  }
                                }
                                &.active {
                                  a {
                                    color: $main-color;
                                  }
                                }
                              }
                            }
                            &.active {
                              a {
                                color: $main-color;
                              }
                            }
                          }
                        }
                        &.active {
                          a {
                            color: $main-color;
                          }
                        }
                      }
                    }
                    &.active {
                      a {
                        color: $main-color;
                      }
                    }
                  }
                }
                &.active {
                  a {
                    color: $main-color;
                  }
                }
              }
            }
            &.active {
              a {
                color: $main-color;
              }
            }
          }
        }
      }
    }
    .others-options {
      margin-left: 20px;

      .cart-btn {
        display: inline-block;
        position: relative;
        top: 3px;
        color: $white-color;

        &:hover {
          color: $main-color;
        }
      }
      .btn {
        margin-left: 15px;
      }
      .btn-primary {
        background-color: $main-color;
        color: $white-color;

        &:hover {
          color: $main-color !important;
          background-color: $white-color !important;
        }
      }
      .option-item {
        color: $black-color;
        display: inline-block;
        position: relative;
        line-height: initial;
        margin-left: 15px;
        top: 4px;

        .search-btn {
          cursor: pointer;
          transition: $transition;
          color: $white-color;

          &::before {
            font-size: 17px;
          }
          &:hover {
            color: $main-color;
          }
        }
        .close-btn {
          cursor: pointer;
          display: none;
          font-size: 17px;
          transition: $transition;
          width: 17px;
          color: $white-color;

          &.active {
            display: block;
          }
          &:hover {
            color: $main-color;
          }
        }
      }
    }
  }
}
.navbar-area {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  z-index: 999;
  background-color: transparent;
  transition: $transition;

  &.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
    background: $white-color !important;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    transition: $transition;

    .kirin-nav {
      background-color: $white-color;
      padding: {
        top: 10px;
        bottom: 10px;
      }
      .navbar {
        .navbar-nav {
          .nav-item {
            a {
              color: $black-color;

              &:hover,
              &:focus,
              &.active {
                color: $main-color;
              }
            }
            &:hover,
            &:focus,
            &.active {
              a {
                color: $main-color;
              }
            }
            .dropdown-menu {
              li {
                a {
                  color: $black-color;

                  &:hover,
                  &:focus,
                  &.active {
                    color: $main-color;
                  }
                }
                .dropdown-menu {
                  li {
                    a {
                      color: $black-color;

                      &:hover,
                      &:focus,
                      &.active {
                        color: $main-color;
                      }
                    }
                    .dropdown-menu {
                      li {
                        a {
                          color: $black-color;

                          &:hover,
                          &:focus,
                          &.active {
                            color: $main-color;
                          }
                        }
                        .dropdown-menu {
                          li {
                            a {
                              color: $black-color;

                              &:hover,
                              &:focus,
                              &.active {
                                color: $main-color;
                              }
                            }
                            .dropdown-menu {
                              li {
                                a {
                                  color: $black-color;

                                  &:hover,
                                  &:focus,
                                  &.active {
                                    color: $main-color;
                                  }
                                }
                                .dropdown-menu {
                                  li {
                                    a {
                                      color: $black-color;

                                      &:hover,
                                      &:focus,
                                      &.active {
                                        color: $main-color;
                                      }
                                    }
                                    .dropdown-menu {
                                      li {
                                        a {
                                          color: $black-color;

                                          &:hover,
                                          &:focus,
                                          &.active {
                                            color: $main-color;
                                          }
                                        }
                                      }
                                    }
                                    &.active {
                                      a {
                                        color: $main-color;
                                      }
                                    }
                                  }
                                }
                                &.active {
                                  a {
                                    color: $main-color;
                                  }
                                }
                              }
                            }
                            &.active {
                              a {
                                color: $main-color;
                              }
                            }
                          }
                        }
                        &.active {
                          a {
                            color: $main-color;
                          }
                        }
                      }
                    }
                    &.active {
                      a {
                        color: $main-color;
                      }
                    }
                  }
                }
                &.active {
                  a {
                    color: $main-color;
                  }
                }
              }
            }
          }
        }
        .others-options {
          .cart-btn {
            color: $black-color;

            &:hover {
              color: $main-color;
            }
          }
          .btn-primary {
            background-color: $main-color;
            color: $white-color;

            &:hover {
              color: $white-color !important;
              background-color: $optional-color !important;
            }
          }
          .option-item {
            color: $black-color;

            .search-btn {
              color: $black-color;

              &:hover {
                color: $main-color;
              }
            }
            .close-btn {
              color: $black-color;

              &:hover {
                color: $main-color;
              }
            }
          }
        }
      }
    }
    .navbar-brand {
      padding: {
        top: 0;
        bottom: 0;
      }
      img {
        &.main-logo {
          display: none;
        }
        &.optional-logo {
          display: block;
        }
      }
    }
  }
  &.navbar-style-two {
    .kirin-nav {
      padding: {
        left: 0;
        right: 0;
      }
    }
  }
}
.search-overlay {
  display: none;

  &.search-popup {
    position: absolute;
    top: 100%;
    width: 300px;
    right: 0;
    background: $white-color;
    z-index: 2;
    padding: 20px;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
    margin-top: 20px;

    .search-form {
      position: relative;

      .search-input {
        display: block;
        width: 100%;
        height: 40px;
        line-height: initial;
        border: 1px solid #eeeeee;
        padding-left: 10px;
        color: $black-color;
        outline: 0;
        transition: $transition;

        &:focus {
          border-color: $main-color;
        }
      }
      .search-button {
        position: absolute;
        right: 0;
        top: 0;
        height: 40px;
        background: transparent;
        border: none;
        width: 40px;
        outline: 0;
        color: $paragraph-color;
        transition: $transition;

        &:hover,
        &:focus {
          color: $main-color;
        }
      }
    }
  }
}
.navbar-style-three {
  .kirin-nav {
    padding: {
      left: 0;
      right: 0;
    }
    .navbar-brand {
      img {
        display: inline-block !important;

        &.optional-logo {
          display: none !important;
        }
      }
    }
    .navbar {
      .navbar-nav {
        .nav-item {
          a {
            color: $black-color;

            &:hover,
            &:focus,
            &.active {
              color: $main-color;
            }
          }
          &:hover,
          &:focus,
          &.active {
            color: $main-color;
          }
          .dropdown-menu {
            li {
              a {
                color: $black-color;

                &:hover,
                &:focus,
                &.active {
                  color: $main-color;
                }
              }
              .dropdown-menu {
                li {
                  a {
                    color: $black-color;

                    &:hover,
                    &:focus,
                    &.active {
                      color: $main-color;
                    }
                  }
                  .dropdown-menu {
                    li {
                      a {
                        color: $black-color;
                        text-transform: capitalize;

                        &:hover,
                        &:focus,
                        &.active {
                          color: $main-color;
                        }
                      }
                      .dropdown-menu {
                        li {
                          a {
                            color: $black-color;

                            &:hover,
                            &:focus,
                            &.active {
                              color: $main-color;
                            }
                          }
                          .dropdown-menu {
                            li {
                              a {
                                color: $black-color;

                                &:hover,
                                &:focus,
                                &.active {
                                  color: $main-color;
                                }
                              }
                              .dropdown-menu {
                                li {
                                  a {
                                    color: $black-color;

                                    &:hover,
                                    &:focus,
                                    &.active {
                                      color: $main-color;
                                    }
                                  }
                                  .dropdown-menu {
                                    li {
                                      a {
                                        color: $black-color;

                                        &:hover,
                                        &:focus,
                                        &.active {
                                          color: $main-color;
                                        }
                                      }
                                    }
                                  }
                                  &.active {
                                    a {
                                      color: $main-color;
                                    }
                                  }
                                }
                              }
                              &.active {
                                a {
                                  color: $main-color;
                                }
                              }
                            }
                          }
                          &.active {
                            a {
                              color: $main-color;
                            }
                          }
                        }
                      }
                      &.active {
                        a {
                          color: $main-color;
                        }
                      }
                    }
                  }
                  &.active {
                    a {
                      color: $main-color;
                    }
                  }
                }
              }
              &.active {
                a {
                  color: $main-color;
                }
              }
            }
          }
        }
      }
      .others-options {
        .cart-btn {
          color: $black-color;

          &:hover {
            color: $main-color;
          }
        }
        .option-item {
          color: $black-color;

          .search-btn {
            color: $black-color;

            &:hover {
              color: $main-color;
            }
          }
          .close-btn {
            color: $black-color;

            &:hover {
              color: $main-color;
            }
          }
        }
      }
    }
  }
}
.navbar-style-four {
  .kirin-nav {
    .navbar-brand {
      img {
        display: inline-block !important;

        &.optional-logo {
          display: none !important;
        }
      }
    }
    .navbar {
      .navbar-nav {
        .nav-item {
          a {
            color: $black-color;

            &:hover,
            &:focus,
            &.active {
              color: $main-color;
            }
          }
          &:hover,
          &:focus,
          &.active {
            color: $main-color;
          }
          .dropdown-menu {
            li {
              a {
                color: $black-color;

                &:hover,
                &:focus,
                &.active {
                  color: $main-color;
                }
              }
              .dropdown-menu {
                li {
                  a {
                    color: $black-color;

                    &:hover,
                    &:focus,
                    &.active {
                      color: $main-color;
                    }
                  }
                  .dropdown-menu {
                    li {
                      a {
                        color: $black-color;
                        text-transform: capitalize;

                        &:hover,
                        &:focus,
                        &.active {
                          color: $main-color;
                        }
                      }
                      .dropdown-menu {
                        li {
                          a {
                            color: $black-color;

                            &:hover,
                            &:focus,
                            &.active {
                              color: $main-color;
                            }
                          }
                          .dropdown-menu {
                            li {
                              a {
                                color: $black-color;

                                &:hover,
                                &:focus,
                                &.active {
                                  color: $main-color;
                                }
                              }
                              .dropdown-menu {
                                li {
                                  a {
                                    color: $black-color;

                                    &:hover,
                                    &:focus,
                                    &.active {
                                      color: $main-color;
                                    }
                                  }
                                  .dropdown-menu {
                                    li {
                                      a {
                                        color: $black-color;

                                        &:hover,
                                        &:focus,
                                        &.active {
                                          color: $main-color;
                                        }
                                      }
                                    }
                                  }
                                  &.active {
                                    a {
                                      color: $main-color;
                                    }
                                  }
                                }
                              }
                              &.active {
                                a {
                                  color: $main-color;
                                }
                              }
                            }
                          }
                          &.active {
                            a {
                              color: $main-color;
                            }
                          }
                        }
                      }
                      &.active {
                        a {
                          color: $main-color;
                        }
                      }
                    }
                  }
                  &.active {
                    a {
                      color: $main-color;
                    }
                  }
                }
              }
              &.active {
                a {
                  color: $main-color;
                }
              }
            }
          }
        }
      }
      .others-options {
        .cart-btn {
          color: $black-color;

          &:hover {
            color: $main-color;
          }
        }
        .option-item {
          color: $black-color;

          .search-btn {
            color: $black-color;

            &:hover {
              color: $main-color;
            }
          }
          .close-btn {
            color: $black-color;

            &:hover {
              color: $main-color;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .kirin-nav {
    .navbar {
      .navbar-nav {
        .nav-item {
          .dropdown-menu {
            position: absolute;
            top: 80px;
            opacity: 0;
            visibility: hidden;
            display: block;
            transition: all 0.3s ease-in-out;

            li {
              .dropdown-menu {
                left: 220px;
                top: 0;
                opacity: 0;
                visibility: hidden;

                li {
                  .dropdown-menu {
                    left: 220px;
                    top: 0;
                    opacity: 0;
                    visibility: hidden;

                    li {
                      .dropdown-menu {
                        left: -250px;
                        top: 0;
                        opacity: 0;
                        visibility: hidden;

                        li {
                          .dropdown-menu {
                            left: -250px;
                            top: 0;
                            opacity: 0;
                            visibility: hidden;

                            li {
                              .dropdown-menu {
                                left: -250px;
                                top: 0;
                                opacity: 0;
                                visibility: hidden;

                                li {
                                  .dropdown-menu {
                                    left: -250px;
                                    top: 0;
                                    opacity: 0;
                                    visibility: hidden;
                                  }
                                  &:hover {
                                    .dropdown-menu {
                                      opacity: 1;
                                      visibility: visible;
                                      top: -15px;
                                    }
                                  }
                                }
                              }
                              &:hover {
                                .dropdown-menu {
                                  opacity: 1;
                                  visibility: visible;
                                  top: -15px;
                                }
                              }
                            }
                          }
                          &:hover {
                            .dropdown-menu {
                              opacity: 1;
                              visibility: visible;
                              top: -15px;
                            }
                          }
                        }
                      }
                      &:hover {
                        .dropdown-menu {
                          opacity: 1;
                          visibility: visible;
                          top: -15px;
                        }
                      }
                    }
                  }
                  &:hover {
                    .dropdown-menu {
                      opacity: 1;
                      visibility: visible;
                      top: -15px;
                    }
                  }
                }
              }
              &:hover {
                .dropdown-menu {
                  opacity: 1;
                  visibility: visible;
                  top: -15px;
                }
              }
            }
          }
          &:hover {
            .dropdown-menu {
              opacity: 1;
              visibility: visible;
              top: 100%;
            }
          }
        }
      }
    }
  }
}
// Mobile Navbar
@media only screen and (max-width: 767px) {
  .navbar-area {
    .kirin-nav {
      border-bottom: none;

      .navbar-light {
        .navbar-toggler {
          color: $black-color;
          // border-color: $black-color;

          .navbar-toggler-icon {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
          }
        }
        #navbarSupportedContent {
          border: 1px solid #eeeeee;
        }
      }
    }

    &.is-sticky {
      box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
      .navbar-light {
        .navbar-toggler {
          border-color: $black-color;
        }
      }
    }
  }
  .kirin-nav {
    border-bottom: 1px solid rgba(255, 255, 255, 0.09);
    padding: {
      top: 15px;
      bottom: 15px;
      right: 0;
      left: 0;
    }
    .navbar {
      .navbar-nav {
        padding: 12px 20px;

        .nav-item {
          padding-top: 8px;
          padding-bottom: 8px;

          a {
            color: #677294;
            margin: {
              left: 0;
              right: 0;
            }
            &.active {
              color: $main-color;
            }
          }
          .dropdown-menu {
            top: 0;
            left: 0 !important;
            right: 0 !important;
            position: relative;
            width: 100%;
            margin-top: 15px;

            .nav-item {
              .dropdown-menu {
                margin-top: 0 !important;
              }
            }
          }
        }
      }
    }
    .navbar-light {
      .navbar-toggler {
        color: $white-color;
        border-color: $white-color;
        border-radius: 0;

        .navbar-toggler-icon {
          background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
        }
      }
      #navbarSupportedContent {
        background-color: $white-color;
        margin-top: 15px;
      }
    }
  }
  .others-options {
    display: none;
  }
  .navbar-style-three {
    .kirin-nav {
      .navbar-light {
        .navbar-toggler {
          color: $black-color;
          border-color: $black-color;

          .navbar-toggler-icon {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
          }
        }
      }
    }
  }
  .navbar-style-four {
    .kirin-nav {
      .navbar-light {
        .navbar-toggler {
          color: $black-color;
          border-color: $black-color;

          // .navbar-toggler-icon {
          //   background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
          // }
        }
      }
    }
  }
}

/*================================================
Main Banner Area CSS
=================================================*/
.flex-container {
  display: flex;
  overflow: hidden;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 125px 0 0 0;
  justify-content: space-evenly;
}
.main-banner {
  height: auto;
  margin-bottom: 60px;
  min-height: 425px;
  position: relative;
  //     background:
  img {
    max-height: 100%;
  }
  background: {
    position: center center;
    size: cover;
    repeat: no-repeat;
  }
  //   background: linear-gradient(
  //         82deg,
  //         rgb(6 6 6 / 44%),
  //         rgb(25 24 24 / 23%)
  //       ),
  //       url(/assets/img/banner/commitment.jpg);
  background: #fff;
  //        background: linear-gradient(
  // 51deg
  // , rgb(69 121 214) 0%, rgb(63 81 181 / 6%) 55%, rgb(8 116 128 / 78%) 100%);
  .container-fluid {
    &.home {
      height: 100%;
    }
    padding: {
      left: 100px;
      right: 100px;
    }
  }
}
.main-banner-content {
  margin-top: -160px;
  max-width: 720px;

  h1 {
    color: $white-color;
    margin-bottom: 0;
    line-height: 1.1;
    font: {
      size: 70px;
      weight: 700;
    }
  }
  p {
    font-size: 21px;
    color: $white-color;
    max-width: 600px;
    margin: {
      top: 25px;
      bottom: 0;
    }
  }
  .btn-box {
    margin-top: 30px;

    .optional-btn {
      display: inline-block;
      color: $white-color;
      position: relative;
      margin-left: 20px;
      font: {
        family: $optional-font-family;
        size: 16px;
        weight: 700;
      }
      &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background-color: $white-color;
        transition: $transition;
      }
      &::after {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        width: 0;
        height: 2px;
        background-color: $main-color;
        transition: $transition;
      }
      &:hover {
        color: $white-color;

        &::before {
          width: 0;
        }
        &::after {
          width: 100%;
          right: auto;
          left: 0;
        }
      }
    }
  }
}
.banner-section {
  overflow: hidden;
  position: relative;
  z-index: 1;
  background: {
    image: url(assets/img/banner-bg-shape.jpg);
    position: center center;
    size: cover;
    repeat: no-repeat;
  }
  padding: {
    top: 240px;
    bottom: 120px;
  }
}
.banner-content {
  h1 {
    margin-bottom: 0;
    line-height: 1.1;
    font: {
      size: 67px;
      weight: 700;
    }
  }
  p {
    font-size: 15px;
    max-width: 600px;
    margin: {
      top: 25px;
      bottom: 0;
    }
  }
  .btn-box {
    margin-top: 30px;

    .optional-btn {
      display: inline-block;
      color: $black-color;
      position: relative;
      margin-left: 20px;
      font: {
        family: $optional-font-family;
        size: 16px;
        weight: 700;
      }
      &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background-color: $black-color;
        transition: $transition;
      }
      &::after {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        width: 0;
        height: 2px;
        background-color: $main-color;
        transition: $transition;
      }
      &:hover {
        color: $main-color;

        &::before {
          width: 0;
        }
        &::after {
          width: 100%;
          right: auto;
          left: 0;
        }
      }
    }
  }
}
.banner-image {
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  display: flex;
  // top: -75px;

  img {
    // position: absolute;

    // &:nth-child(1) {
    //   left: 115px;
    //   bottom: 70px;
    //   z-index: 4;
    //   animation-name: rotateMe;
    //   animation-duration: 20s;
    //   animation-iteration-count: infinite;
    //   animation-timing-function: linear;
    // }
    &:nth-child(2) {
      left: 87px;
      bottom: 45px;
      z-index: 3;
    }
    &:nth-child(3) {
      right: -43px;
      bottom: -35px;
      border: 3px solid #35cff6;
      height: 185px;
    }
    &:nth-child(4) {
      left: -40px;
      bottom: -80px;
      z-index: 2;
      width: 157px;
    }
    &:nth-child(5) {
      left: 40px;
      bottom: -55px;
      z-index: 2;
    }
    &:nth-child(6) {
      left: -45px;
      top: 185px;
      z-index: 1;
    }
    &:nth-child(7) {
      right: 160px;
      top: 0;
    }
    &:nth-child(8) {
      left: 30px;
      bottom: -63px;
    }
    &.main-pic {
      display: none;
    }
  }
}
.hero-banner {
  position: relative;
  z-index: 1;
  background: {
    image: url(assets/img/banner-bg-shape.jpg);
    position: center center;
    size: cover;
    repeat: no-repeat;
  }
  padding: {
    top: 180px;
    bottom: 100px;
  }
  .container-fluid {
    padding: {
      left: 70px;
      right: 70px;
    }
  }
}
.hero-banner-content {
  h1 {
    margin-bottom: 0;
    line-height: 1.1;
    font: {
      size: 67px;
      weight: 700;
    }
  }
  p {
    font-size: 15px;
    max-width: 600px;
    margin: {
      top: 25px;
      bottom: 0;
    }
  }
  .btn-box {
    margin-top: 30px;

    .optional-btn {
      display: inline-block;
      color: $black-color;
      position: relative;
      margin-left: 20px;
      font: {
        family: $optional-font-family;
        size: 16px;
        weight: 700;
      }
      &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background-color: $black-color;
        transition: $transition;
      }
      &::after {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        width: 0;
        height: 2px;
        background-color: $main-color;
        transition: $transition;
      }
      &:hover {
        color: $main-color;

        &::before {
          width: 0;
        }
        &::after {
          width: 100%;
          right: auto;
          left: 0;
        }
      }
    }
  }
}
.hero-banner-image {
  text-align: center;
}

/*================================================
Featured Services Area CSS
=================================================*/
.featured-services-area {
  // position: absolute;
  // left: 0;
  // bottom: 0;
  width: 100%;
  height: auto;
  background-color: transparent;
}
.single-featured-services-box {
  position: relative;
  z-index: 1;
  box-shadow: 2px 1px 7px 2px #c4c3c3;
  transition: $transition;
  padding: 20px 25px;

  .icon {
    margin-bottom: 15px;
    color: $main-color;
    transition: $transition;
    line-height: 1;

    i {
      font-size: 45px;

      &::before {
        font-size: 45px;
      }
    }
  }
  h3 {
    margin-bottom: 0;
    color: $white-color;
    transition: $transition;
    font: {
      size: 23px;
      weight: 700;
    }
    a {
      // color: #fff;
      color: $main-color;
    }
  }
  p {
    color: #252323;
    transition: $transition;
    margin: {
      top: 12px;
      bottom: 0;
    }
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background-color: $main-color;
    width: 2px;
    height: 100%;
    transition: $transition;
    z-index: -1;
  }
  &:hover,
  &.active {
    .icon {
      color: $white-color;
    }
    h3 {
      color: $white-color;

      a {
        color: $white-color;
      }
    }
    p {
      color: #f0f0f0;
    }
    &::before {
      width: 100%;
    }
  }
}

/*================================================
Featured Solutions Area CSS
=================================================*/
.featured-solutions-area {
  padding-bottom: 80px;
  position: relative;
  z-index: 1;
}
.single-featured-solutions-box {
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
  padding: 35px;
  border-radius: 5px;
  background: linear-gradient(
    to left bottom,
    #dee4f3,
    #e7eaf6,
    #f0f1f9,
    #f8f8fc,
    #ffffff
  );

  .icon {
    position: relative;
    z-index: 1;
    margin-bottom: 25px;

    img {
      transition: $transition;
    }
    &::before {
      height: 50px;
      width: 50px;
      background: linear-gradient(
        to right,
        #dee4f3,
        #e7eaf6,
        #f0f1f9,
        #f8f8fc,
        #ffffff
      );
      position: absolute;
      left: -10px;
      top: 50%;
      transform: translateY(-50%);
      z-index: -1;
      content: "";
      border-radius: 50%;
      transition: $transition;
    }
  }
  h3 {
    margin-bottom: 0;
    transition: $transition;
    font: {
      size: 23px;
      weight: 700;
    }
  }
  p {
    margin: {
      top: 13px;
      bottom: 0;
    }
  }
  .learn-more-btn {
    margin-top: 12px;
    font-size: 16px;

    &::before {
      width: 100%;
      opacity: 1;
      visibility: visible;
    }
    &:hover {
      &::before {
        width: 0;
      }
    }
  }
  &:hover {
    .icon {
      img {
        transform: rotateY(-180deg);
      }
      &::before {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }
}

/*================================================
About Area CSS
=================================================*/
.about-area {
  position: relative;
  z-index: 1;
}
.about-image {
  position: relative;
  padding-bottom: 100px;

  img {
    &:nth-child(2) {
      position: absolute;
      right: 0;
      bottom: 0;
      border-radius: 0 10px 0 0;
    }
  }
}
.about-content {
  padding-left: 25px;

  h2 {
    margin-bottom: 18px;
    line-height: 1.3;
    margin-top: -10px;
    font: {
      size: 40px;
      weight: 700;
    }
  }
  p {
    margin-bottom: 13px;
  }
  .btn {
    margin-top: 10px;
  }
}
.about-inner-area {
  margin-top: 60px;
}
.about-text {
  h3 {
    margin-bottom: 0;
    font: {
      size: 23px;
      weight: 700;
    }
  }
  p {
    margin: {
      top: 13px;
      bottom: 0;
    }
  }
}
.about-img {
  text-align: center;
}
.about-content {
  .features-list {
    padding-left: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    margin: {
      bottom: 20px;
      right: -10px;
      left: -10px;
    }
    li {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
      padding: {
        left: 10px;
        right: 10px;
        top: 13px;
      }
      span {
        display: block;
        position: relative;
        color: $black-color;
        padding-left: 28px;
        font: {
          family: $optional-font-family;
          weight: 600;
          size: 17px;
        }
        i {
          width: 20px;
          display: inline-block;
          height: 20px;
          border-radius: 2px;
          position: absolute;
          left: 0;
          top: 4px;
          color: $white-color;
          line-height: 16px;
          background-color: $main-color;
          text-align: center;
          transition: $transition;

          &::before {
            font-size: 10px;
          }
        }
        &:hover {
          i {
            background-color: $optional-color;
          }
        }
      }
    }
  }
}
.shape-img1 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: -1;
  opacity: 0.4;
}
.shape-img2 {
  position: absolute;
  left: 5%;
  top: 5%;
  z-index: -1;
  animation-name: rotateMe;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.shape-img7 {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0.2;
  animation: rotate3d 4s linear infinite;
}
.shape-img3 {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
  opacity: 0.1;
  animation: rotate3d 4s linear infinite;
}
.shape-img4 {
  position: absolute;
  right: 40%;
  bottom: 8%;
  z-index: -1;
  animation: animationFramesOne 20s infinite linear;
}
.shape-img5 {
  position: absolute;
  right: 5%;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
  opacity: 0.7;
  animation-name: rotateMe;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.shape-img6 {
  position: absolute;
  left: 2%;
  bottom: 0;
  z-index: -1;
  opacity: 0.4;
  animation: moveLeftBounce 5s linear infinite;
}
.dot-shape1 {
  position: absolute;
  top: 5%;
  left: 50%;
  z-index: -1;
  animation: animationFramesTwo 40s infinite linear;
  opacity: 0.5;
}
.dot-shape2 {
  position: absolute;
  left: 5%;
  top: 45%;
  z-index: -1;
  animation: animationFramesTwo 30s infinite linear;
  opacity: 0.5;
}
.dot-shape4 {
  position: absolute;
  right: 15%;
  top: 50%;
  z-index: -1;
  animation: rotate3d 4s linear infinite;
}
.dot-shape5 {
  position: absolute;
  left: 30%;
  top: 50%;
  z-index: -1;
  animation: animationFramesOne 20s infinite linear;
}
.dot-shape6 {
  position: absolute;
  right: 45%;
  bottom: 5%;
  z-index: -1;
  animation: rotate3d 4s linear infinite;
}
@keyframes moveBounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes rotateMe {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes rotate3d {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}
@keyframes animationFramesOne {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    transform: translate(-73px, 1px) rotate(-36deg);
  }
  40% {
    transform: translate(-141px, -72px) rotate(-72deg);
  }
  60% {
    transform: translate(-83px, -122px) rotate(-108deg);
  }
  80% {
    transform: translate(40px, -72px) rotate(-144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}
@keyframes moveLeftBounce {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes animationFramesTwo {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    transform: translate(73px, -1px) rotate(36deg);
  }
  40% {
    transform: translate(141px, 72px) rotate(72deg);
  }
  60% {
    transform: translate(83px, 122px) rotate(108deg);
  }
  80% {
    transform: translate(-40px, 72px) rotate(144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}

/*================================================
Services Area CSS
=================================================*/
.services-area {
  padding-bottom: 70px;
  position: relative;
  z-index: 1;

  .pagination-area {
    margin-bottom: 30px;
  }
}
.single-services-box,
.app-details {
  background-color: #ffffff;
  margin-bottom: 30px;
  padding: 30px 25px;
  transition: $transition;
  position: relative;
  text-align: center;

  .icon {
    margin-bottom: 20px;
    background-color: #0d2c6636;
    border-radius: 5px;
    width: 75px;
    height: 75px;
    line-height: 75px;
    transition: $transition;
    text-align: center;
    color: $main-color;
    margin-left: auto;
    margin-right: auto;

    i {
      &::before {
        font-size: 35px;
      }
    }
  }
  h3 {
    margin-bottom: 0;
    font: {
      size: 23px;
      weight: 700;
    }
  }
  p {
    margin: {
      top: 12px;
      bottom: 0;
    }
  }
  &::before {
    content: "";
    height: 2px;
    width: 20px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: $main-color;
    transition: $transition;
  }
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 0 15px 3px rgba(0, 0, 0, 0.05);
    cursor: pointer;

    .icon {
      background-color: $main-color;
      color: $white-color;
    }
    &::before {
      width: 100%;
      height: 2px;
    }
  }
}
.app-details {
  box-shadow: 0px 0px 0.5px 0px #06060652;
  &:hover {
    transform: translateY(0) !important;
  }
}

.services-section {
  position: relative;
  z-index: 1;
}
.services-tab-list {
  .tabs {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;

    li {
      margin-bottom: 10px;

      a {
        display: block;
        background-color: $white-color;
        position: relative;
        padding: 20px;
        border-radius: 5px;
        color: #313e5b;
        font: {
          size: 19px;
          weight: 600;
        }
        i {
          display: inline-block;
          width: 55px;
          height: 55px;
          background: linear-gradient(
            to left bottom,
            #dee4f3,
            #e7eaf6,
            #f0f1f9,
            #f8f8fc,
            #ffffff
          );
          text-align: center;
          line-height: 55px;
          border-radius: 5px;
          margin-right: 7px;
          color: $main-color;

          &::before {
            font-size: 30px;
          }
        }
        &:hover {
          background-color: $main-color;
          color: $white-color;
        }
      }
      &.current {
        a {
          background-color: $main-color;
          color: $white-color;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .tab-content {
    padding-left: 30px;

    .tabs-item {
      .image {
        margin-bottom: 30px;
      }
      .content {
        .btn {
          margin-top: 5px;
        }
      }
    }
  }
}

/*================================================
Services Details Area CSS
=================================================*/
.services-details-overview {
  -ms-flex-align: center !important;
  align-items: center !important;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: {
    right: -15px;
    left: -15px;
    top: 50px;
  }
  &:first-child {
    margin-top: 0;
  }
  .services-details-desc {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding: {
      right: 15px;
      left: 15px;
    }
    h3 {
      margin-bottom: 15px;
      font: {
        size: 23px;
        weight: 700;
      }
    }
    .services-details-accordion {
      .accordion {
        list-style-type: none;
        padding-left: 0;
        margin: {
          bottom: 0;
          top: 25px;
        }
        .accordion-item {
          display: block;
          box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
          background: $white-color;
          margin-bottom: 20px;
          // transition: all 250ms ease-in-out;
          min-height: 0;
          .accordion-content {
            display: block;
            min-height: 0;
            transition: min-height 300 ease-in;
          }

          &:last-child {
            margin-bottom: 0;
          }
          &.active {
            .accordion-content {
              display: block;
              min-height: 50px;
            }
            .accordion-title {
              border-bottom-color: #eeeeee;
              &.active {
                i {
                  &::before {
                    content: "\f068";
                  }
                }
              }
            }
          }
        }
        .accordion-title {
          padding: 12px 20px 12px 51px;
          color: $black-color;
          transition: all 250ms ease-in-out;
          position: relative;
          border-bottom: 1px solid transparent;
          margin-bottom: -1px;
          font: {
            size: 15px;
            weight: 700;
            family: $optional-font-family;
          }
          i {
            position: absolute;
            left: 0;
            top: 0;
            width: 40px;
            text-align: center;
            height: 100%;
            background: $main-color;
            color: $white-color;

            &::before {
              position: absolute;
              left: 0;
              top: 50%;
              right: 0;
              transform: translateY(-50%);
              font-size: 13px;
            }
          }
        }
        .accordion-content {
          display: none;
          position: relative;
          padding: 15px 15px;
          transition: all 250ms ease-in-out;
          min-height: 0;
        }
      }
    }
  }
  .services-details-image {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding: {
      right: 15px;
      left: 15px;
    }
  }
}

/*================================================
Webinar Area CSS
=================================================*/
.webinar-content {
  margin-left: auto;

  text-align: left;
  max-width: 555px;
  padding: {
    top: 130px;
    bottom: 130px;
    right: 87px;
  }
  h2 {
    margin-bottom: 15px;
    font: {
      size: 40px;
      weight: 700;
    }
  }
  p {
    margin-bottom: 15px;
  }
  .btn {
    margin-top: 10px;
  }
}
.webinar-video-image {
  &.mission {
    background: {
      image: url(assets/img/our-mission.jpg);
    }
  }
  &.vision {
    background: {
      image: url(assets/img/our-vision.jpg);
    }
  }
  background: {
    position: center center;
    size: cover;
    repeat: no-repeat;
  }
}
.webinar-video-image,
.webinar-video {
  position: relative;
  text-align: center;
  width: 100%;
  height: 100%;

  img {
    display: none;
  }
  .video-btn {
    position: absolute;
    left: -45px;
    display: inline-block;
    top: 50%;
    transform: translateY(-50%);
    width: 90px;
    height: 90px;
    line-height: 90px;
    background-color: $main-color;
    border-radius: 50%;
    color: $white-color;
    z-index: 1;

    i {
      &::before {
        font-size: 38px;
        padding-left: 5px;
      }
    }
    &::after,
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      z-index: -1;
      bottom: 0;
      left: 0;
      border-radius: 50%;
      border: 1px solid $main-color;
    }
    &::before {
      animation: ripple 2s linear infinite;
    }
    &::after {
      animation: ripple 2s linear 1s infinite;
    }
    &:hover,
    &:focus {
      background-color: $optional-color;
      color: $white-color;
    }
  }
}
@keyframes ripple {
  0% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.75);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

/*================================================
Projects Area CSS
=================================================*/
.projects-area {
  position: relative;
  overflow: hidden;
  z-index: 1;
  padding-bottom: 80px;

  .section-title {
    text-align: left;
    max-width: 540px;
    margin: {
      left: 0;
      right: 0;
    }
  }
  .container-fluid {
    position: relative;
  }
}
.single-projects-box {
  position: relative;
  border-radius: 5px;
  margin-bottom: 30px;
  height: 200px;
  display: flex;
  justify-content: center;
  padding: 20px;
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.175);
  background-color: $white-color;

  img {
    border-radius: 5px;
  }
  .plus-icon {
    a {
      background-color: $main-color;
      width: 70px;
      height: 70px;
      position: absolute;
      top: 20px;
      right: 20px;
      transform: scale(0);
      transition: all 0.4s ease-out 0s;
      overflow: hidden;
      border-radius: 50%;

      span {
        width: 25px;
        height: 25px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        &::before,
        &::after {
          position: absolute;
          content: "";
          width: 2px;
          height: 100%;
          background-color: $white-color;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
        }
        &::after {
          height: 2px;
          width: 100%;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
      }
      &:hover {
        background-color: $optional-color;
      }
    }
  }
  &:hover {
    .plus-icon {
      a {
        transform: scale(1);
      }
    }
  }
}
.projects-slides {
  &.owl-theme {
    .owl-stage-outer {
      padding: {
        left: 15px;
        right: 15px;
      }
    }
    .owl-nav {
      margin-top: 0;
      position: absolute;
      right: 20%;
      top: -115px;

      [class*="owl-"] {
        color: $black-color;
        font-size: 15px;
        margin: 0;
        padding: 0;
        background-color: transparent;
        display: inline-block;
        width: 45px;
        height: 45px;
        line-height: 45px;
        transition: $transition;
        position: relative;
        border-radius: 30% 70% 70% 30% / 40% 60% 40% 60%;
        z-index: 1;

        &.owl-next {
          margin-left: 10px;
          border-radius: 70% 30% 30% 70% / 60% 40% 60% 40%;

          &::before {
            border-radius: 70% 30% 30% 70% / 60% 40% 60% 40%;
          }
        }
        &::before {
          width: 100%;
          height: 100%;
          background: linear-gradient(
            to left bottom,
            #dee4f3,
            #e7eaf6,
            #f0f1f9,
            #f8f8fc,
            #ffffff
          );
          border-radius: 30% 70% 70% 30% / 40% 60% 40% 60%;
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          z-index: -1;
          transition: $transition;
        }
        &:hover {
          background-color: $main-color;
          color: $white-color;

          &::before {
            opacity: 0;
            visibility: hidden;
          }
        }
      }
    }
  }
}
.circle-shape1 {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

/*================================================
Projects Details Area CSS
=================================================*/
.projects-details {
  padding: 40px;
  box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
  background-color: $white-color;
}
.projects-details-image {
  box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.3);
  background-color: $white-color;
  height: 100%;
  padding-top: 1rem;

  display: flex;
  align-items: center;
}
.projects-details-info {
  width: 100%;
  height: 100%;
  padding: 25px;
  box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
  background-color: $white-color;

  ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;

    li {
      margin-bottom: 12px;
      color: $paragraph-color;

      &:last-child {
        margin-bottom: 0;
      }
      a {
        display: inline-block;
        color: $paragraph-color;

        &:hover {
          color: $main-color;
        }
      }
      span {
        color: $black-color;
        font: {
          weight: 600;
          size: 16px;
        }
      }
    }
  }
}
.projects-details-desc {
  margin-top: 35px;

  h3 {
    margin-bottom: 12px;
    font: {
      size: 23px;
      weight: 700;
    }
  }
  p {
    margin-bottom: 12px;
  }
  blockquote,
  .blockquote {
    p {
      font-size: 25px;
    }
  }
}

/*================================================
Team Area CSS
=================================================*/
.team-area {
  padding-bottom: 70px;
}
.single-team-box {
  margin-bottom: 30px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  background-color: $white-color;
  transition: $transition;
  text-align: center;

  .image {
    position: relative;
    overflow: hidden;

    img {
      transition: $transition;
    }
    .social {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      text-decoration: none;
      opacity: 0;
      margin-top: -100px;
      padding: 8px;
      background: $white-color;
      border-radius: 35px;
      visibility: hidden;
      transition: $transition;

      a {
        display: inline-block;
        color: $main-color;
        font-size: 15px;
        margin: 0 5.5px;

        &:hover {
          color: $optional-color;
        }
      }
    }
  }
  .content {
    padding: 25px;
    position: relative;
    transition: $transition;

    h3 {
      margin-bottom: 0;
      transition: $transition;
      font: {
        size: 16px;
        weight: 700;
      }
    }
    span {
      display: block;
      margin-top: 8px;
      color: $main-color;
      transition: $transition;
      font: {
        family: $optional-font-family;
        size: 13.5px;
        weight: 500;
      }
    }
  }
  &:hover {
    transform: translateY(-5px);

    .image {
      img {
        transform: scale(1.2);
      }
      .social {
        opacity: 1;
        margin-top: 0;
        visibility: visible;
      }
    }
    .content {
      background-color: $main-color;

      h3 {
        color: $white-color;
      }
      span {
        color: $white-color;
      }
    }
  }
}

/*================================================
Fun Facts Area CSS
=================================================*/
.fun-facts-area {
  position: relative;
  z-index: 1;
}
.single-funfacts {
  margin-bottom: 30px;
  text-align: center;

  h3 {
    color: $main-color;
    margin-bottom: 0;
    font: {
      size: 32px;
      weight: 700;
    }

    .odometer-auto-theme {
      font: {
        family: $optional-font-family;
      }
    }
  }
  p {
    line-height: initial;
    margin-bottom: 0;
  }
}
.contact-cta-box {
  max-width: 600px;
  border-radius: 5px;
  border: 1px solid #f5f5f5;
  padding: 25px 220px 25px 40px;
  position: relative;
  margin: {
    left: auto;
    top: 40px;
    right: auto;
  }
  h3 {
    margin-bottom: 0;
    font: {
      size: 23px;
      weight: 600;
    }
  }
  p {
    line-height: initial;
    margin: {
      top: 10px;
      bottom: 0;
    }
  }
  .btn {
    position: absolute;
    right: 40px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.shape-map1 {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
  text-align: center;

  margin: {
    left: auto;
    right: auto;
  }
}

/*================================================
Partner Area CSS
=================================================*/
.partner-area {
  padding-bottom: 100px;
}
.customers-partner-list {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: {
    right: -5px;
    left: -5px;
  }
}
.partner-item {
  -ms-flex: 0 0 14%;
  flex: 0 0 14%;
  max-width: 14%;
  padding: {
    left: 5px;
    right: 5px;
  }
  a {
    display: block;
    padding: 15px;
    background: $white-color;
    text-align: center;
    position: relative;
    margin-bottom: 10px;

    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      opacity: 0;
      visibility: hidden;
      transition: $transition;
    }
    &::before {
      left: 0;
      top: 0;
      border: {
        left: 3px solid $main-color;
        top: 3px solid $main-color;
      }
    }
    &::after {
      right: 0;
      bottom: 0;
      border: {
        right: 3px solid $main-color;
        bottom: 3px solid $main-color;
      }
    }
    img {
      transition: $transition;

      height: 65px;
    }
    &:hover {
      &::after,
      &::before {
        width: 40px;
        height: 40px;
        opacity: 1;
        visibility: visible;
      }
      img {
        transform: scale(0.8);
      }
    }
  }
  // &:nth-child(6) {
  //   margin-left: 10%;
  // }
}
.partner-section {
  position: relative;
  padding-bottom: 80px;
  z-index: 1;
}
.single-partner-item {
  margin-bottom: 30px;
  text-align: center;

  a {
    display: block;

    img {
      width: auto !important;
      display: inline-block !important;
    }
  }
}

/*================================================
Feedback Area CSS
=================================================*/
.feedback-area {
  position: relative;
  z-index: 1;
}
.feedback-item {
  position: relative;
  z-index: 3;
  margin-left: auto;
  max-width: 555px;
  padding: {
    top: 100px;
    bottom: 100px;
  }
  p {
    margin-bottom: 0;
    color: $white-color;
    font: {
      size: 19px;
      style: italic;
    }
  }
  &::before {
    content: "\f113";
    color: $white-color;
    display: inline-block;
    position: relative;
    line-height: 1;
    margin-bottom: 20px;
    font: {
      style: normal;
      size: 50px;
      family: Flaticon;
    }
  }
  .client-info {
    margin-top: 20px;

    img {
      margin-bottom: 9px;
      border-radius: 50%;
      width: 65px;
    }
    h3 {
      margin-bottom: 0;
      color: $white-color;
      font: {
        size: 17px;
        weight: 700;
      }
    }
    span {
      display: block;
      font-weight: 300;
      margin-top: 4px;
      color: $white-color;
    }
  }
  &::after {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    background-color: $main-color;
    z-index: -1;
    display: none;
  }
}
.client-image {
  position: relative;
  text-align: center;
  width: 100%;
  height: 100%;
  background: {
    position: center center;
    size: cover;
    repeat: no-repeat;
  }
  img {
    display: none !important;
    width: auto !important;
  }
  // &.bg1 {
  //   background: {
  //     image: url(assets/img/client/client1.png);
  //   }
  // }
  // &.bg2 {
  //   background: {
  //     image: url(assets/img/client/client2.png);
  //   }
  // }
  // &.bg3 {
  //   background: {
  //     image: url(assets/img/client/client3.jpg);
  //   }
  // }
}
.feedback-slides {
  &.owl-theme {
    .owl-stage {
      .owl-item {
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 68%;
          height: 100%;
          z-index: 1;
          background: {
            image: url(assets/img/1.png);
            position: right top;
            size: cover;
            repeat: no-repeat;
          }
        }
      }
    }
    .owl-nav {
      margin-top: 0;
      transition: $transition;
      opacity: 0;
      visibility: hidden;

      [class*="owl-"] {
        position: absolute;
        left: 50px;
        top: 50%;
        transform: translateY(-50%);
        background-color: transparent !important;
        color: $white-color;
        outline: 0;
        transition: $transition;
        font-size: 50px;

        i {
          &::before {
            font-size: 50px;
          }
        }
        &.owl-next {
          left: auto;
          right: 50px;
        }
      }
    }
    &:hover {
      .owl-nav {
        opacity: 1;
        visibility: visible;
      }
    }
    .owl-dots {
      position: absolute;
      left: 0;
      bottom: 15px;
      right: 0;

      .owl-dot {
        outline: 0;

        span {
          background-color: $white-color;
          transition: $transition;
          margin: 0 4px;
          border-radius: 50%;
          width: 12px;
          height: 12px;
        }
        &.active,
        &:hover {
          span {
            background-color: #3a3a3a;
          }
        }
      }
    }
  }
}
.testimonials-area {
  background: linear-gradient(
    to right,
    #dee4f3,
    #e7eaf6,
    #f0f1f9,
    #f8f8fc,
    #ffffff
  );
  padding-bottom: 80px;
  position: relative;
  z-index: 1;
}
.single-testimonials-item {
  box-shadow: 0 0 25px 3px rgba(0, 0, 0, 0.04);
  margin-bottom: 45px;
  background-color: $white-color;
  position: relative;
  padding: 60px 70px 60px 190px;
  border-radius: 5px;

  .client-info {
    position: absolute;
    left: 70px;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;

    img {
      border-radius: 5px;
      border: 2px solid #f4f5fe;
      width: 80px !important;
      height: 80px !important;
      padding: 5px;
      display: inline-block !important;
    }
    h3 {
      margin: {
        bottom: 0;
        top: 10px;
      }
      font: {
        size: 19px;
        weight: 700;
      }
    }
    span {
      display: block;
      color: $main-color;
      margin-top: 5px;
      font-size: 13px;
    }
  }
  p {
    font-size: 15px;
    margin-bottom: 0;
    color: #4a6f8a;
  }
  .rating {
    margin-top: 10px;

    i {
      color: #ff612f;

      &::before {
        font-size: 14px;
      }
    }
  }
  &::before {
    content: "\f113";
    color: #f5f5f5;
    position: absolute;
    right: 25px;
    bottom: 0;
    font: {
      style: normal;
      size: 80px;
      family: Flaticon;
    }
  }
  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    border-radius: 5px;
    bottom: -15px;
    background-color: $white-color;
    box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, 0.04);
    z-index: -1;
    margin: {
      left: 30px;
      right: 30px;
    }
  }
}
.testimonials-slides {
  max-width: 750px;
  margin: -40px auto 0;

  .single-testimonials-item {
    margin-top: 40px;
  }
  .owl-item {
    padding: {
      left: 50px;
      right: 50px;
    }
  }
  .owl-nav {
    &.disabled + .owl-dots {
      line-height: 0.01;
      margin: {
        top: 10px;
        bottom: 30px;
      }
    }
  }
  .owl-dots {
    .owl-dot {
      span {
        width: 15px;
        height: 15px;
        margin: 0 3px;
        background: transparent;
        transition: $transition;
        border: 2px solid #8d95a7;
        border-radius: 50%;
        position: relative;

        &::before {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background-color: $main-color;
          margin: 2px;
          content: "";
          transition: $transition;
          border-radius: 50%;
          transform: scale(0);
        }
      }
      &:hover,
      &.active {
        span {
          border-color: $main-color;
          background-color: transparent;

          &::before {
            transform: scale(1);
          }
        }
      }
    }
  }
}

/*================================================
Pricing Area CSS
=================================================*/
.pricing-area {
  position: relative;
  z-index: 1;
  padding-bottom: 70px;
}
.single-pricing-box {
  background-color: $white-color;
  margin-bottom: 30px;
  padding: 30px;
  transition: $transition;
  border-radius: 5px;

  .pricing-header {
    h3 {
      margin-bottom: 0;
      font: {
        size: 26px;
        weight: 700;
      }
    }
    p {
      transition: $transition;
      line-height: 1.6;
      margin: {
        top: 10px;
        bottom: 0;
      }
    }
  }
  .price {
    font: {
      size: 50px;
      weight: 700;
      family: $optional-font-family;
    }
    margin: {
      bottom: 10px;
    }
    span {
      display: inline-block;
      margin-left: -7px;
      font: {
        size: 20px;
        weight: 600;
      }
    }
  }
  .buy-btn {
    margin-bottom: 25px;

    .btn-primary {
      &::after {
        background: linear-gradient(
          to right top,
          #a3a3a3,
          #9a9a9a,
          #909090,
          #878787,
          #7e7e7e
        );
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        transition: $transition;
        z-index: -1;
        border-radius: 30px;
      }
    }
  }
  .pricing-features {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;

    li {
      margin-bottom: 12px;
      color: $paragraph-color;
      position: relative;
      padding-left: 19px;

      &:last-child {
        margin-bottom: 0;
      }
      i {
        color: $main-color;
        font-size: 12px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);

        &::before {
          font-size: 12px;
        }
      }
    }
  }
  &:hover,
  &.active {
    box-shadow: 0 0 40px 3px rgba(0, 0, 0, 0.05);
    background-color: $white-color;
    border-color: $white-color;

    .buy-btn {
      .btn-primary {
        &::after {
          opacity: 0;
          visibility: hidden;
        }
      }
    }
  }
}
.tabs-item {
  display: none;

  &:first-child {
    display: block;
  }
}
.pricing-tab {
  .tabs {
    padding-left: 0;
    margin-bottom: 45px;
    list-style-type: none;
    text-align: center;

    li {
      display: inline-block;

      a {
        background-color: $white-color;
        padding: 10px 30px 11px;
        margin: {
          left: -2px;
          right: -2px;
        }
        font: {
          family: $optional-font-family;
          weight: 600;
          size: 16px;
        }
        &:hover {
          color: $white-color;
          background-color: $black-color;
        }
      }
      &.current {
        a {
          color: $white-color;
          background-color: $black-color;
        }
      }
      &:nth-child(1) {
        a {
          border-radius: 5px 0 0 5px;

          &::before {
            border-radius: 5px 0 0 5px;
          }
        }
      }
      &:nth-child(2) {
        a {
          border-radius: 0 5px 5px 0;

          &::before {
            border-radius: 0 5px 5px 0;
          }
        }
      }
    }
  }
}
.pricing-box {
  background: linear-gradient(
    to left bottom,
    #dee4f3,
    #e7eaf6,
    #f0f1f9,
    #f8f8fc,
    #ffffff
  );
  margin-bottom: 30px;
  text-align: center;
  padding: 30px;
  transition: $transition;
  border-radius: 5px;

  .pricing-header {
    h3 {
      margin-bottom: 0;
      font: {
        size: 26px;
        weight: 700;
      }
    }
    p {
      transition: $transition;
      line-height: 1.6;
      margin: {
        top: 10px;
        bottom: 0;
      }
    }
  }
  .price {
    font: {
      size: 50px;
      weight: 700;
      family: $optional-font-family;
    }
    margin: {
      bottom: 10px;
    }
    span {
      display: inline-block;
      margin-left: -7px;
      font: {
        size: 20px;
        weight: 600;
      }
    }
  }
  .buy-btn {
    margin-bottom: 25px;

    .btn-primary {
      &::after {
        background: linear-gradient(
          to right top,
          #a3a3a3,
          #9a9a9a,
          #909090,
          #878787,
          #7e7e7e
        );
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        transition: $transition;
        z-index: -1;
        border-radius: 30px;
      }
    }
  }
  .pricing-features {
    margin-bottom: 0;
    list-style-type: none;
    text-align: left;
    padding: {
      left: 15px;
      right: 15px;
    }
    li {
      margin-bottom: 12px;
      color: #495771;
      position: relative;
      padding-left: 19px;

      &:last-child {
        margin-bottom: 0;
      }
      i {
        color: $main-color;
        font-size: 12px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);

        &::before {
          font-size: 12px;
        }
      }
    }
  }
  &:hover,
  &.active {
    .buy-btn {
      .btn-primary {
        &::after {
          opacity: 0;
          visibility: hidden;
        }
      }
    }
  }
}
.pricing-tab {
  &.bg-color {
    .tabs {
      li {
        a {
          background: linear-gradient(
            to left bottom,
            #dee4f3,
            #e7eaf6,
            #f0f1f9,
            #f8f8fc,
            #ffffff
          );

          &:hover {
            color: $black-color;
            background: linear-gradient(
              to left bottom,
              #dee4f3,
              #e7eaf6,
              #f0f1f9,
              #f8f8fc,
              #ffffff
            );
          }
        }
        &.current {
          a {
            color: $white-color;
            background: $black-color;
          }
        }
      }
    }
  }
}

/*================================================
Blog Area CSS
=================================================*/
/* .blog-area {
  position: relative;
  z-index: 1;
  padding-bottom: 70px;

  .pagination-area {
    margin-bottom: 30px;
  }
}
.single-blog-post {
  margin-bottom: 30px;
  transition: $transition;
  border-radius: 5px;
  background-color: #fafafa;

  .entry-thumbnail {
    a {
      img {
        transition: $transition;
        border-radius: 5px;
      }
    }
  }
  .entry-post-content {
    padding: 20px;

    .entry-meta {
      margin-bottom: 6px;

      ul {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
          display: inline-block;
          position: relative;
          margin-right: 17px;
          color: $paragraph-color;
          font: {
            family: $optional-font-family;
            size: 15px;
          }
          &::before {
            content: "";
            position: absolute;
            right: -7px;
            top: 50%;
            transform: rotate(14deg) translateY(-50%);
            background: $paragraph-color;
            width: 1px;
            height: 13px;
          }
          a {
            display: inline-block;
            color: $paragraph-color;

            &:hover {
              color: $main-color;
            }
          }
          &:last-child {
            margin-right: 0;

            &::before {
              display: none;
            }
          }
        }
      }
    }
    h3 {
      margin-bottom: 0;
      line-height: 1.4;
      font: {
        size: 23px;
        weight: 700;
      }
    }
    p {
      margin: {
        top: 8px;
        bottom: 0;
      }
    }
    .learn-more-btn {
      margin-top: 15px;
    }
  }
  &:hover {
    box-shadow: 0 0 40px 3px rgba(0, 0, 0, 0.05);
    transform: translateY(-5px);
    background-color: $white-color;

    .entry-thumbnail {
      a {
        img {
          opacity: 0.7;
        }
      }
    }
  }
} */

/*================================================
Blog Details Area CSS
=================================================*/
/* .blog-details {
  .article-content {
    margin-top: 30px;

    .entry-meta {
      margin-bottom: 10px;

      ul {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;
        font-family: $optional-font-family;

        li {
          position: relative;
          display: inline-block;
          color: $black-color;
          margin-right: 25px;
          font-size: 15px;

          span {
            display: inline-block;
            color: $black-color;
            font-weight: 600;
            padding-right: 5px;
          }
          a {
            display: inline-block;
            color: $paragraph-color;

            &:hover {
              color: $main-color;
            }
          }
          i {
            color: $main-color;
            margin-right: 2px;
          }
          &::before {
            content: "";
            position: absolute;
            top: 11px;
            right: -15px;
            width: 6px;
            height: 1px;
            background: $main-color;
          }
          &:last-child {
            margin-right: 0;

            &::before {
              display: none;
            }
          }
        }
      }
    }
    h3 {
      margin-bottom: 15px;
      font: {
        size: 23px;
        weight: 700;
      }
    }
    .wp-block-gallery {
      &.columns-3 {
        padding-left: 0;
        list-style-type: none;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin: {
          right: -10px;
          left: -10px;
          bottom: 30px;
          top: 30px;
        }
        li {
          -ms-flex: 0 0 33.3333%;
          flex: 0 0 33.3333%;
          max-width: 33.3333%;
          padding: {
            right: 10px;
            left: 10px;
          }
          figure {
            margin-bottom: 0;
          }
        }
      }
    }
    .article-features-list {
      padding-left: 0;
      list-style-type: none;
      margin: {
        bottom: 15px;
        left: 20px;
      }
      li {
        margin-bottom: 12px;
        color: $paragraph-color;
        position: relative;
        padding-left: 15px;

        &::before {
          background: $main-color;
          height: 7px;
          width: 7px;
          content: "";
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          border-radius: 50%;
          position: absolute;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .article-footer {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: {
      top: 15px;
      bottom: 15px;
    }
    border: {
      top: 1px solid #eeeeee;
      bottom: 1px solid #eeeeee;
    }
    margin: {
      top: 30px;
    }
    .article-tags {
      -ms-flex: 0 0 50%;
      -webkit-box-flex: 0;
      flex: 0 0 50%;
      max-width: 50%;

      span {
        display: inline-block;
        color: $black-color;
        font-size: 20px;
        margin-right: 10px;
        position: relative;
        top: 2px;
      }
      a {
        display: inline-block;
        color: #818181;

        &:hover {
          color: $main-color;
        }
      }
    }
    .article-share {
      -ms-flex: 0 0 50%;
      -webkit-box-flex: 0;
      flex: 0 0 50%;
      max-width: 50%;

      .social {
        padding-left: 0;
        list-style-type: none;
        text-align: right;
        margin: {
          bottom: 0;
        }
        li {
          display: inline-block;
          margin-left: 5px;

          a {
            color: $black-color;
            background-color: #f7f7f7;
            width: 33px;
            height: 33px;
            line-height: 33px;
            text-align: center;
            border-radius: 50%;
            font-size: 13px;

            &:hover {
              color: $white-color;
              background-color: $main-color;
            }
          }
        }
      }
    }
  }
} 
blockquote,
.blockquote {
  overflow: hidden;
  background-color: #fafafa;
  padding: 50px !important;
  position: relative;
  text-align: center;
  z-index: 1;
  font-family: $optional-font-family;
  margin: {
    bottom: 20px;
    top: 20px;
  }
  p {
    color: $black-color;
    line-height: 1.6;
    margin-bottom: 0;
    font: {
      style: italic;
      weight: 600;
      size: 20px;
    }
  }
  cite {
    display: none;
  }
  &::before {
    color: #efefef;
    content: "\f10d";
    position: absolute;
    left: 50px;
    top: -60px;
    z-index: -1;
    font: {
      family: "Font Awesome 5 Free";
      size: 140px;
      weight: 900;
    }
  }
}
.comments-area {
  margin-top: 30px;

  .comments-title {
    line-height: initial;
    margin: {
      bottom: 30px;
    }
    font: {
      size: 23px;
      weight: 700;
    }
  }
  ol,
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
  .comment-list {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
  }
  .children {
    margin-left: 20px;
  }
  .comment-body {
    border-bottom: 1px solid #eeeeee;
    padding-left: 65px;
    color: $black-color;
    font-size: 14px;
    margin-bottom: 20px;
    padding-bottom: 20px;

    .reply {
      margin-top: 15px;

      a {
        border: 1px solid #eeeeee;
        color: $paragraph-color;
        display: inline-block;
        padding: 5px 20px;
        border-radius: 30px;
        text-transform: uppercase;
        font: {
          size: 12px;
          weight: 700;
          family: $optional-font-family;
        }
        &:hover {
          color: $white-color;
          background-color: $main-color;
          border-color: $main-color;
        }
      }
    }
  }
  .comment-meta {
    margin-bottom: 0.8em;
  }
  .comment-author {
    font-size: 16px;
    margin-bottom: 0.4em;
    position: relative;
    z-index: 2;

    .avatar {
      height: 50px;
      left: -65px;
      position: absolute;
      width: 50px;
    }
    .fn {
      font: {
        weight: 700;
        family: $optional-font-family;
      }
    }
    .says {
      display: none;
    }
  }
  .comment-metadata {
    color: $paragraph-color;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    font: {
      size: 11px;
      weight: 600;
    }
    a {
      color: $paragraph-color;

      &:hover {
        color: $main-color;
      }
    }
  }
  .comment-content {
    p {
      font-size: 14px;
    }
  }
  .comment-respond {
    .comment-reply-title {
      margin-bottom: 0;
      font: {
        size: 23px;
        weight: 700;
      }
      #cancel-comment-reply-link {
        font-size: 15px;
        display: inline-block;
      }
    }
    .comment-form {
      overflow: hidden;
    }
    .comment-notes {
      font-size: 14px;
      margin: {
        bottom: 0;
        top: 10px;
      }
      .required {
        color: red;
      }
    }
    .comment-form-comment {
      margin-top: 15px;
      float: left;
      width: 100%;
    }
    label {
      display: block;
      margin-bottom: 5px;
      color: $black-color;
      font-size: 16px;
      font: {
        weight: 600;
        family: $optional-font-family;
      }
    }
    input[type="date"],
    input[type="time"],
    input[type="datetime-local"],
    input[type="week"],
    input[type="month"],
    input[type="text"],
    input[type="email"],
    input[type="url"],
    input[type="password"],
    input[type="search"],
    input[type="tel"],
    input[type="number"],
    textarea {
      display: block;
      width: 100%;
      background-color: $white-color;
      border: 1px solid #eeeeee;
      padding: 0.625em 0.7375em;
      outline: 0;
      transition: $transition;

      &:focus {
        border-color: $main-color;
      }
    }
    .comment-form-author {
      float: left;
      width: 50%;
      padding-right: 10px;
      margin-bottom: 20px;
    }
    .comment-form-email {
      float: left;
      width: 50%;
      padding-left: 12px;
      margin-bottom: 20px;
    }
    .comment-form-url {
      float: left;
      width: 100%;
      margin-bottom: 20px;
    }
    .comment-form-cookies-consent {
      width: 100%;
      float: left;
      position: relative;
      padding-left: 20px;
      margin-bottom: 20px;

      input {
        position: absolute;
        left: 0;
        top: 6px;
      }
      label {
        display: inline-block;
        margin: 0;
        color: $paragraph-color;
        font-weight: normal;
        font-weight: 500;
      }
    }
    .form-submit {
      float: left;
      width: 100%;

      input {
        background-color: $main-color;
        border: none;
        color: $white-color;
        padding: 10.5px 25px;
        display: inline-block;
        cursor: pointer;
        outline: 0;
        border-radius: 0;
        text-transform: uppercase;
        transition: $transition;
        font: {
          family: $optional-font-family;
          weight: 700;
          size: 14px;
        }
        &:hover,
        &:focus {
          color: $white-color;
          background-color: $optional-color;
        }
      }
    }
  }
}
*/
/*================================================
Free Trial Area CSS
=================================================*/
.free-trial-area {
  position: relative;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 68%;
    height: 100%;
    z-index: 1;
    background: {
      image: url(assets/img/2.png);
      position: left top;
      size: cover;
      repeat: no-repeat;
    }
  }
}
.free-trial-content {
  position: relative;
  z-index: 2;
  max-width: 630px;
  padding-left: 30px;
  padding: {
    top: 100px;
    bottom: 100px;
  }
  h2 {
    margin-bottom: 0;
    color: $white-color;
    font: {
      size: 40px;
      weight: 700;
    }
  }
  form {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: {
      top: 25px;
      bottom: 10px;
    }
    .input-newsletter {
      // display: block;
      // width: 72%;
      height: 45px;
      min-width: 35%;
      color: $white-color;
      border: none;
      outline: 0;
      background-color: $black-color;
      border-radius: 30px;
      padding-left: 20px;

      &::placeholder {
        color: #d5d5d5;
      }
    }
    button {
      // position: absolute;
      right: 0;
      top: 0;
      height: 45px;
      border: none;
      padding: 0 30px;
      line-height: 45px;
      background-color: $white-color;
      border-radius: 30px;
      transition: $transition;
      color: $main-color;
      font: {
        family: $optional-font-family;
        size: 16px;
        weight: 600;
      }
      &:hover {
        background-color: $optional-color;
        color: $white-color;
      }
    }
  }
  p {
    color: $white-color;
    margin-bottom: 0;
    max-width: 350px;
  }
  &::after {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    background-color: $main-color;
    z-index: -1;
    display: none;
  }
}
.free-trial-image {
  position: relative;
  text-align: center;
  width: 100%;
  height: 100%;
  background: {
    position: center center;
    size: cover;
    repeat: no-repeat;
    image: url(assets/img/keep.jpg);
  }
  img {
    display: none;
  }
}
.free-trial-section {
  position: relative;
  z-index: 1;

  &::before {
    width: 40%;
    height: 100%;
    z-index: -1;
    position: absolute;
    right: 0;
    top: 0;
    background: linear-gradient(
      to left bottom,
      #f8f9fd,
      #fafafd,
      #fcfcfe,
      #fdfdfe,
      #ffffff
    );
    content: "";
  }
}
.free-trial-img {
  margin-left: -15px;
  text-align: center;
}
.free-trial-text {
  padding-left: 15px;

  h2 {
    margin-bottom: 0;
    font: {
      size: 40px;
      weight: 700;
    }
  }
  form {
    position: relative;
    margin: {
      top: 25px;
      bottom: 10px;
    }
    .input-newsletter {
      display: block;
      width: 72%;
      height: 45px;
      color: $white-color;
      border: none;
      outline: 0;
      background-color: $black-color;
      border-radius: 30px;
      padding-left: 20px;

      &::placeholder {
        color: #d5d5d5;
        transition: $transition;
      }
      &:focus {
        &::placeholder {
          color: transparent;
        }
      }
    }
    button {
      position: absolute;
      right: 0;
      top: 0;
      height: 45px;
      border: none;
      padding: 0 30px;
      line-height: 45px;
      background-color: $main-color;
      border-radius: 30px;
      transition: $transition;
      color: $white-color;
      font: {
        family: $optional-font-family;
        size: 16px;
        weight: 600;
      }
      &:hover {
        background-color: $optional-color;
        color: $white-color;
      }
    }
  }
  p {
    margin-bottom: 0;
  }
}

/*================================================
Page Title Area CSS
=================================================*/
.page-title-area {
  &.desc-project {
    background-image: linear-gradient(to bottom right, #0000008c, #00090aa1),
      url(assets/img/projects/project-desc.jpg);
  }
  position: relative;
  z-index: 1;
  // min-height: 80vh;

  min-height: 40vh;
  // min-height: 40vh;
  // padding-top: 100px;
  // padding-bottom: 100px;
  padding: {
    top: 25vh;
    bottom: 10vh;
    // bottom: 140px;
  }
  background: {
    // color: #000000 !important;
    position: center center !important;
    size: cover !important;
    repeat: no-repeat !important;
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    // background-color: #000000;
    opacity: 0.6;
  }
  &.item-bg1 {
    background: {
      image: linear-gradient(to bottom right, #0000008c, #00090aa1),
        url(assets/img/banner/about.jpg);
      // image: url(assets/img/page-title-bg1.jpg);
    }
  }
  &.item-bg2 {
    background: {
      image: linear-gradient(to bottom right, #0000008c, #00090aa1),
        url(assets/img/banner/services.jpg);
    }
  }
  &.item-bg3 {
    background: {
      image: linear-gradient(to bottom right, #0000008c, #00090aa1),
        url(assets/img/banner/projects.png);
    }
  }
  &.item-bg4 {
    background: {
      image: linear-gradient(to bottom right, #0000008c, #00090aa1),
        url(assets/img/banner/contacts.jpg);
    }
  }
  &.item-bg5 {
    background: {
      image: linear-gradient(to bottom right, #0000008c, #00090aa1),
        url(assets/img/banner/marketplace.png);
    }
  }
}
.page-title-content {
  h2 {
    margin-bottom: 0;
    color: $white-color;
    font: {
      size: 40px;
      weight: 700;
    }
  }
  ul {
    padding-left: 0;
    list-style-type: none;
    font-family: $optional-font-family;
    margin: {
      top: 10px;
      bottom: 0;
    }
    li {
      display: inline-block;
      color: $white-color;
      margin: {
        right: 15px;
        left: 10px;
      }
      position: relative;
      font: {
        weight: 600;
        size: 16px;
      }
      a {
        color: #92eef8;

        &:hover {
          color: $main-color;
        }
      }
      &::before {
        content: "\f054";
        position: absolute;
        right: -15px;
        top: 6px;
        font: {
          weight: 900;
          family: "Font Awesome 5 Free";
          size: 10px;
        }
      }
      &:last-child {
        margin-right: 0;

        &::before {
          display: none;
        }
      }
      &:first-child {
        margin-left: 0;
      }
    }
  }
}

/*================================================
Pagination Area CSS
=================================================*/
.pagination-area {
  margin-top: 30px;
  text-align: center;
  font-family: $optional-font-family;

  .page-numbers {
    width: 40px;
    height: 40px;
    margin: 0 3px;
    display: inline-block;
    background-color: $white-color;
    line-height: 40px;
    color: $black-color;
    box-shadow: 0 2px 10px 0 #d8dde6;
    font: {
      size: 16px;
      weight: 700;
    }
    &.current,
    &:hover,
    &:focus {
      background: $main-color;
      color: $white-color;
      box-shadow: unset;
    }
  }
}

/*================================================
Sidebar Area CSS
=================================================*/
.widget-area {
  .widget {
    margin-top: 30px;

    &:first-child {
      margin-top: 0;
    }
    .widget-title {
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 10px;
      margin-bottom: 20px;
      text-transform: capitalize;
      position: relative;
      font: {
        weight: 700;
        size: 20px;
      }
      &::before {
        content: "";
        position: absolute;
        background: $main-color;
        bottom: -1px;
        left: 0;
        width: 50px;
        height: 1px;
      }
    }
  }
  .widget_search {
    box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
    background-color: $white-color;
    padding: 15px;

    form {
      position: relative;

      label {
        display: block;
        margin-bottom: 0;
      }
      .screen-reader-text {
        display: none;
      }
      .search-field {
        background-color: transparent;
        height: 50px;
        padding: 6px 15px;
        border: 1px solid #eeeeee;
        width: 100%;
        display: block;
        outline: 0;
        transition: $transition;

        &:focus {
          border-color: $main-color;
        }
      }
      button {
        position: absolute;
        right: 0;
        outline: 0;
        bottom: 0;
        height: 50px;
        width: 50px;
        z-index: 1;
        border: none;
        color: $white-color;
        background-color: $main-color;
        transition: $transition;

        &:hover {
          background-color: $optional-color;
          color: $white-color;
        }
      }
    }
  }
  .widget_kirin_posts_thumb {
    position: relative;
    overflow: hidden;

    .item {
      overflow: hidden;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
      .thumb {
        float: left;
        height: 80px;
        overflow: hidden;
        position: relative;
        width: 80px;
        margin-right: 15px;

        .fullimage {
          width: 80px;
          height: 80px;
          display: inline-block;
          background: {
            size: cover !important;
            repeat: no-repeat;
            position: center center !important;
          }
          // &.bg1 {
          //   background-image: url(assets/img/blog/blog1.jpg);
          // }
          // &.bg2 {
          //   background-image: url(assets/img/blog/blog2.jpg);
          // }
          // &.bg3 {
          //   background-image: url(assets/img/blog/blog3.jpg);
          // }
        }
      }
      .info {
        overflow: hidden;

        time {
          display: block;
          color: $paragraph-color;
          text-transform: uppercase;
          margin: {
            top: 5px;
            bottom: 5px;
          }
          font: {
            size: 11px;
          }
        }
        .title {
          margin-bottom: 0;
          line-height: 1.4;
          font: {
            size: 16px;
            weight: 700;
          }
        }
      }
    }
  }
  .widget_recent_entries {
    ul {
      padding-left: 0;
      margin-bottom: 0;
      list-style-type: none;

      li {
        position: relative;
        margin-bottom: 12px;
        color: $paragraph-color;
        padding-left: 14px;
        line-height: 1.5;
        font: {
          weight: 600;
          size: 14.5px;
          family: $optional-font-family;
        }
        &:last-child {
          margin-bottom: 0;
        }
        &::before {
          background: $main-color;
          position: absolute;
          height: 7px;
          width: 7px;
          content: "";
          left: 0;
          top: 7px;
        }
        a {
          color: $paragraph-color;

          &:hover {
            color: $main-color;
          }
        }
        .post-date {
          display: block;
          font-size: 13px;
          color: $paragraph-color;
          margin-top: 4px;
        }
      }
    }
  }
  .widget_recent_comments {
    ul {
      padding-left: 0;
      margin-bottom: 0;
      list-style-type: none;

      li {
        position: relative;
        margin-bottom: 12px;
        color: $black-color;
        padding-left: 14px;
        line-height: 1.5;
        font: {
          size: 14.5px;
          weight: 600;
          family: $optional-font-family;
        }
        &:last-child {
          margin-bottom: 0;
        }
        &::before {
          background: $main-color;
          height: 7px;
          width: 7px;
          content: "";
          left: 0;
          top: 7px;
          position: absolute;
        }
        span {
          display: inline-block;
        }
        a {
          color: $paragraph-color;
          display: inline-block;

          &:hover {
            color: $main-color;
          }
        }
      }
    }
  }
  .widget_archive {
    ul {
      padding-left: 0;
      margin-bottom: 0;
      list-style-type: none;

      li {
        position: relative;
        margin-bottom: 12px;
        padding-left: 14px;
        color: $paragraph-color;
        font: {
          size: 14.5px;
          weight: 600;
          family: $optional-font-family;
        }
        &:last-child {
          margin-bottom: 0;
        }
        &::before {
          background: $main-color;
          height: 7px;
          width: 7px;
          content: "";
          left: 0;
          top: 7px;
          position: absolute;
        }
        a {
          color: $paragraph-color;

          &:hover {
            color: $main-color;
          }
        }
      }
    }
  }
  .widget_categories {
    ul {
      padding-left: 0;
      margin-bottom: 0;
      list-style-type: none;

      li {
        position: relative;
        margin-bottom: 12px;
        color: $paragraph-color;
        padding-left: 14px;
        font: {
          size: 14.5px;
          family: $optional-font-family;
          weight: 600;
        }
        &:last-child {
          margin-bottom: 0;
        }
        &::before {
          background: $main-color;
          height: 7px;
          width: 7px;
          content: "";
          left: 0;
          top: 7px;
          position: absolute;
        }
        a {
          color: $paragraph-color;

          &:hover {
            color: $main-color;
          }
        }
        .post-count {
          float: right;
        }
      }
    }
  }
  .widget_meta {
    ul {
      padding-left: 0;
      margin-bottom: 0;
      list-style-type: none;

      li {
        position: relative;
        margin-bottom: 12px;
        color: $paragraph-color;
        padding-left: 14px;
        font: {
          size: 14.5px;
          family: $optional-font-family;
          weight: 600;
        }
        &:last-child {
          margin-bottom: 0;
        }
        &::before {
          background: $main-color;
          height: 7px;
          width: 7px;
          content: "";
          left: 0;
          top: 7px;
          position: absolute;
        }
        a {
          color: $paragraph-color;

          &:hover {
            color: $main-color;
          }
        }
      }
    }
  }
  .widget_tag_cloud {
    .widget-title {
      margin-bottom: 12px;
    }
  }
  .tagcloud {
    a {
      display: inline-block;
      color: $paragraph-color;
      font: {
        weight: 600;
        size: 12.5px !important;
      }
      padding: 6px 13px;
      border: 1px dashed #eeeeee;
      margin: {
        top: 8px;
        right: 4px;
      }
      &:hover,
      &:focus {
        color: $white-color;
        background-color: $main-color;
        border-color: $main-color;
      }
    }
  }
}

/*================================================
404 Error Area CSS
=================================================*/
.error-area {
  background-color: $black-color;
  padding: {
    bottom: 100px;
    top: 150px;
  }
}
.error-content {
  text-align: center;
  margin: 0 auto;
  max-width: 700px;

  h3 {
    color: $white-color;
    font: {
      size: 40px;
      weight: 700;
    }
    margin: {
      top: 30px;
      bottom: 17px;
    }
  }
  p {
    max-width: 520px;
    color: $white-color;
    margin: 0 auto 20px;
  }
  .btn-primary {
    background-color: $white-color;
    color: $black-color;
  }
}

/*================================================
FAQ Area CSS
=================================================*/
.faq-content {
  .section-title {
    text-align: left;
    max-width: 100%;
    margin: {
      left: 0;
      right: 0;
      bottom: 20px;
    }
    p {
      max-width: 100%;
      margin: {
        left: 0;
        right: 0;
      }
    }
  }
}
.faq-accordion {
  .accordion {
    list-style-type: none;
    padding-left: 0;
    margin: {
      bottom: 0;
    }
    .accordion-item {
      display: block;
      box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
      background: $white-color;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
      &.active {
        .accordion-content {
          display: block;
        }
        .accordion-title {
          border-bottom-color: #eeeeee;

          i {
            &::before {
              content: "\f068";
            }
          }
        }
      }
    }
    .accordion-title {
      padding: 12px 20px 12px 51px;
      color: $black-color;
      position: relative;
      border-bottom: 1px solid transparent;
      margin-bottom: -1px;
      font: {
        size: 15px;
        weight: 700;
        family: $optional-font-family;
      }
      i {
        position: absolute;
        left: 0;
        top: 0;
        width: 40px;
        text-align: center;
        height: 100%;
        background: $main-color;
        color: $white-color;

        &::before {
          position: absolute;
          left: 0;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          font-size: 13px;
        }
      }
    }
    .accordion-content {
      display: none;
      position: relative;
      padding: 15px 15px;
    }
  }
}
.faq-contact {
  margin-top: 100px;
}
.faq-contact-image {
  img {
    max-width: 300px;
    width: 100%;
  }
}
.faq-contact-form {
  text-align: center;

  form {
    padding-left: 50px;

    .form-control {
      background-color: $white-color;
      border: none;
      box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
      height: 50px;
      font: {
        family: $optional-font-family;
        size: 16px;
      }
    }
    .row {
      margin: {
        left: -7px;
        right: -7px;
      }
      .col-lg-12,
      .col-lg-6 {
        padding: {
          left: 7px;
          right: 7px;
        }
      }
    }
    textarea.form-control {
      height: auto;
      padding-top: 15px;
    }
    .btn {
      padding: 13px 35px 15px;
      font-size: 17px;
      margin-top: 8px;
      box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);

      &.disabled {
        cursor: not-allowed !important;

        &:hover,
        &:focus {
          &::before {
            opacity: 1;
            visibility: visible;
          }
          &::after {
            opacity: 0;
            visibility: hidden;
          }
        }
      }
    }
  }
}

/*================================================
Coming Soon Area CSS
=================================================*/
.coming-soon-area {
  position: relative;
  z-index: 1;

  .coming-soon-content {
    height: 100vh;
    position: relative;
    padding: {
      left: 100px;
      right: 100px;
    }
    .logo {
      position: absolute;
      left: 100px;
      top: 30px;
    }
    h3 {
      line-height: 1.3;
      margin: {
        bottom: 25px;
      }
      font: {
        size: 40px;
        weight: 700;
      }
    }
    form {
      max-width: 520px;
      .form-group {
        margin-bottom: 25px;
      }
      .form-control {
        height: 50px;
        border: none;
        box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
      }
      .btn {
        display: block;
        width: 100%;
        padding: 15px 28px;
        box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
      }
      p {
        margin: {
          bottom: 0;
          top: 15px;
        }
      }
    }
    .social {
      position: absolute;
      left: 100px;
      bottom: 30px;
      text-align: center;

      ul {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
          display: inline-block;
          margin-right: 5px;
          &:last-child {
            margin-right: 0;
          }
          a {
            width: 35px;
            height: 35px;
            line-height: 37px;
            border-radius: 50%;
            background-color: #dddddd;
            color: $black-color;
            font-size: 14px;

            &.twitter {
              background-color: #1da1f2;
              color: $white-color;
            }
            &.youtube {
              background-color: #ff0000;
              color: $white-color;
            }
            &.facebook {
              background-color: #3b5998;
              color: $white-color;
            }
            &.linkedin {
              background-color: #0077b5;
              color: $white-color;
            }
            &.instagram {
              background-color: #c13584;
              color: $white-color;
            }
            &:hover {
              background-color: $main-color;
              color: $white-color;
            }
          }
        }
      }
    }
  }
  .coming-soon-time {
    width: 100%;
    height: 100%;
    position: relative;
    text-align: center;
    z-index: 1;
    background: {
      image: url(assets/img/coming-soon-bg.jpg);
      position: center center;
      size: cover;
      repeat: no-repeat;
    }
    img {
      display: none;
    }
    #timer {
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2;
      max-width: 420px;
      margin: {
        left: auto;
        right: auto;
      }
      div {
        width: 155px;
        height: 155px;
        border: 1px solid rgba(255, 255, 255, 0.3);
        border-radius: 50%;
        display: inline-block;
        color: $white-color;
        line-height: initial;
        padding-top: 37px;
        margin: {
          right: 15px;
          left: 15px;
          bottom: 15px;
          top: 15px;
        }
        font: {
          size: 45px;
          weight: 700;
          family: $optional-font-family;
        }
        span {
          text-transform: uppercase;
          display: block;
          margin-top: 3px;
          font: {
            size: 14px;
            weight: 600;
          }
        }
      }
    }
    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: #000000;
      opacity: 0.7;
      z-index: -1;
    }
  }
}

/*================================================
Contact Area CSS
=================================================*/
.contact-area {
  .section-title {
    margin-bottom: 40px;
  }
}
.contact-image {
  img {
    max-width: 300px;
    width: 100%;
  }
}
.contact-form {
  text-align: center;

  form {
    padding-left: 50px;

    .form-control {
      background-color: $white-color;
      border: none;
      box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
      height: 50px;
      font: {
        family: $optional-font-family;
        size: 16px;
      }
    }
    .row {
      margin: {
        left: -7px;
        right: -7px;
      }
      .col-lg-12,
      .col-lg-6 {
        padding: {
          left: 7px;
          right: 7px;
        }
      }
    }
    textarea.form-control {
      height: auto;
      padding-top: 15px;
    }
    .btn {
      padding: 13px 35px 15px;
      font-size: 17px;
      margin-top: 10px;
      box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.5);

      &.disabled {
        cursor: not-allowed !important;

        &:hover,
        &:focus {
          &::before {
            opacity: 1;
            visibility: visible;
          }
          &::after {
            opacity: 0;
            visibility: hidden;
          }
        }
      }
    }
  }
}
.contact-info {
  text-align: center;
  margin-top: 60px;

  .contact-info-content {
    h3 {
      margin-bottom: 15px;
      font: {
        size: 23px;
        weight: 700;
      }
    }
    h2 {
      margin-bottom: 0;
      font: {
        size: 30px;
        weight: 700;
      }
      a {
        display: inline-block;
        color: $main-color;

        &:hover {
          color: $optional-color;
        }
        &:not(:first-child) {
          color: $optional-color;

          &:hover {
            color: $main-color;
          }
        }
      }
      span {
        display: block;
        color: $paragraph-color;
        margin: {
          top: 8px;
          bottom: 0;
        }
        font: {
          size: 18px;
          weight: 500;
        }
      }
    }
    .social {
      padding-left: 0;
      list-style-type: none;
      margin: {
        bottom: 20px;
        top: 20px;
      }
      li {
        display: inline-block;
        margin: 0 2px;

        a {
          width: 35px;
          height: 35px;
          line-height: 35px;
          border: 1px solid #dadada;
          border-radius: 50%;
          color: #aba5a5;

          i {
            font-size: 14px;
          }
          &:hover {
            color: $white-color;
            border-color: $main-color;
            background-color: $main-color;
          }
        }
      }
    }
  }
}

/*================================================
Footer Area CSS
=================================================*/
.footer-area {
  position: relative;
  z-index: 1;
  padding-top: 100px;
  background-color: #15171a;
}
.single-footer-widget {
  margin-bottom: 30px;

  .logo {
    margin-bottom: 20px;

    p {
      color: $white-color;
      // margin-top: 7px;
      // margin-bottom: 0;
      padding-right: 1px;
      font-size: 13px;
      margin: {
        top: 7px;
        bottom: 0;
      }
    }
  }
  p {
    color: $white-color;
    font-size: 14px;
  }
  .social {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;

    li {
      display: inline-block;
      transition: $transition;
      margin: {
        right: 5px;
      }
      a {
        width: 35px;
        height: 35px;
        background-color: $black-color;
        line-height: 35px;
        border-radius: 50%;
        color: $white-color;
        text-align: center;

        i {
          &::before {
            font-size: 13px;
          }
        }
        &:hover {
          background-color: $main-color;
          color: $white-color;
        }
      }
    }
  }
  h3 {
    margin-bottom: 20px;
    color: $white-color;
    position: relative;
    padding-bottom: 10px;
    font: {
      size: 23px;
      weight: 700;
    }
    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 40px;
      height: 2px;
      background: $main-color;
    }
  }
  .footer-services-list {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;

    li {
      margin-bottom: 12px;
      color: $white-color;
      font-size: 14px;

      a {
        display: inline-block;
        color: $white-color;

        &:hover {
          color: $main-color;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .quick-links-list {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;

    li {
      margin-bottom: 12px;
      color: $white-color;
      font-size: 14px;

      a {
        display: inline-block;
        color: $white-color;

        &:hover {
          color: $main-color;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .footer-contact-list {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;

    li {
      margin-bottom: 10px;
      color: $white-color;
      font-size: 14px;
      line-height: 1.8;

      a {
        display: inline-block;
        color: $white-color;

        &:hover {
          color: $main-color;
        }
      }
      span {
        font-weight: 700;
        margin-right: 5px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.copyright-area {
  background-color: #000000;
  margin-top: 70px;
  padding: {
    top: 20px;
    bottom: 20px;
  }
  p {
    color: $white-color;

    a {
      display: inline-block;
      color: $white-color;
      font-weight: 600;

      &:hover {
        color: $main-color;
      }
    }
  }
  ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
    text-align: right;

    li {
      display: inline-block;
      margin-left: 20px;
      position: relative;
      color: $white-color;
      font-size: 14px;
      line-height: initial;

      a {
        color: $white-color;

        &:hover {
          color: $main-color;
        }
      }
      &::before {
        content: "";
        width: 4px;
        height: 1px;
        background: $white-color;
        left: -11px;
        top: 50%;
        position: absolute;
        transform: translateY(-50%);
      }
      &:first-child {
        margin-left: 0;

        &::before {
          display: none;
        }
      }
    }
  }
}
.circle-map {
  position: absolute;
  top: 20%;
  right: 18%;
  z-index: -1;
}
.lines {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  margin: auto;
  width: 90vw;
  z-index: -1;

  .line {
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    left: 50%;
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;

    &::after {
      content: "";
      display: block;
      position: absolute;
      height: 15vh;
      width: 100%;
      top: -50%;
      left: 0;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        $white-color 75%,
        $white-color 100%
      );
      animation: run 7s 0s infinite;
      animation-fill-mode: forwards;
      animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
    }
    &:nth-child(1) {
      margin-left: -25%;

      &::after {
        animation-delay: 2s;
      }
    }
    &:nth-child(3) {
      margin-left: 25%;

      &::after {
        animation-delay: 2.5s;
      }
    }
  }
}
@keyframes run {
  0% {
    top: -50%;
  }
  100% {
    top: 110%;
  }
}

/*================================================
Go Top CSS
=================================================*/
.go-top {
  position: fixed;
  cursor: pointer;
  top: 0;
  right: 15px;
  color: $white-color;
  background: $main-color;
  z-index: 4;
  width: 40px;
  text-align: center;
  height: 42px;
  line-height: 42px;
  opacity: 0;
  visibility: hidden;
  transition: 0.9s;

  &.active {
    top: 98%;
    transform: translateY(-98%);
    opacity: 1;
    visibility: visible;
  }
  i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    transition: $transition;

    &:last-child {
      opacity: 0;
      visibility: hidden;
      top: 60%;
    }
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: $optional-color;
    opacity: 0;
    visibility: hidden;
    transition: $transition;
  }
  &:hover,
  &:focus {
    color: $white-color;

    &::before {
      opacity: 1;
      visibility: visible;
    }
    i {
      &:first-child {
        opacity: 0;
        top: 0;
        visibility: hidden;
      }
      &:last-child {
        opacity: 1;
        visibility: visible;
        top: 50%;
      }
    }
  }
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
